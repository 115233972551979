import * as signalR from '@aspnet/signalr';
import { UtilsString } from '@/utils/utils-string';
import { ssmMessageService } from '@/shared/services/message-service';
import { ssmTokenService } from '@/shared/services/token-service';
import { MessageListenFor } from '@/shared/dtos/message-listen-for';
import notificationModule from '@/components/header/Notification/notification-module';
import { VueInstanceService } from './vue-instance-service';
//import { NotificationDto } from '@/components/header/Notification/notification-dto';

export default class SignalRService {
    public connection!: signalR.HubConnection;
    public messages: MessageListenFor[] = [];
    public connecting: boolean = false;
    public connected: boolean = false;
    public invoking: boolean = false;
    public joinedChannel: boolean = false;
    private webChannelHubBase: string = 'falta llamar a ItiSignalRService.setWebChannelHubBase()';

    public setWebChannelHubBase(webChannelHubBase: string) {
        this.webChannelHubBase = webChannelHubBase;
        this.connect();
    }
    public RespuestaSignalR() {
        /*let notification: NotificationDto = new NotificationDto();
        notification.index = 0;
        notification.read = false;
        notification.title = "New Message";
        notification.msg = "Are your going to meet me tonight?";
        notification.icon = "date_range";
        notification.time = new Date();
        notification.category = "primary";
        notificationModule.onNewNotification(notification);*/
        return true;
    }

    public UpdateAvisosSignalR() {
        notificationModule.getAvisos();
        return true;
    }

    public listenFor(messageListenFor: string, messageEventMethod: (...args: any[]) => void) {
        const message = new MessageListenFor({ EventName: MessageListenFor, EventMethod: messageEventMethod });
        if (this.connected && this.joinedChannel) {
            this.addListenFor(messageListenFor, messageEventMethod);
        } else {
            this.saveMessage(message);
        }
    }

    public unListenFor(messageListenFor: string, eventMethod: (...args: any[]) => void) {
        this.connection.off(messageListenFor, eventMethod);
    }

    public connect(): Promise<any> {
        if (this.connected) {
            return Promise.resolve(true);
        }
        if (!this.connecting) {
            this.connecting = true;
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.webChannelHubBase, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets, accessTokenFactory: () => ssmTokenService.getToken()! })
                .configureLogging(signalR.LogLevel.Information)
                .build();
            /* this.connection = new signalR.HubConnectionBuilder()
                     .withUrl(this.webChannelHubBase, { skipNegotiation: false, transport: signalR.HttpTransportType.LongPolling, accessTokenFactory: () => ssmTokenService.getToken()! })
                     .configureLogging(signalR.LogLevel.Information)
                     .build();*/
            //this.connection = new signalR.HubConnectionBuilder().withUrl(this.webChannelHubBase).build();
            /*  this.connection = new signalR.HubConnectionBuilder()
                  .withUrl(this.webChannelHubBase, { skipNegotiation: false, transport: signalR.HttpTransportType.ServerSentEvents, accessTokenFactory: () => ssmTokenService.getToken()! })
                  .configureLogging(signalR.LogLevel.Information)
                  .build();*/

            this.connection.onclose(this.onConnectionError.bind(this));
            return this.connection.start()
                .then(this.onConnectionStart.bind(this))
                .catch(this.onConnectionError.bind(this));
        } else {
            return Promise.resolve(false);
        }
    }

    public joinChannel(): Promise<any> {
        if (this.joinedChannel) {
            return Promise.resolve(true);
        }
        if (!this.invoking && ssmTokenService.hasToken()) {
            this.invoking = true;
            this.addListenFor('JoinChannelLog', () => this.RespuestaSignalR());
            this.addListenFor('UpdateAvisos', () => this.UpdateAvisosSignalR());
            return this.connection.invoke('JoinToMyChannel')
                .then(this.onJoinChannel.bind(this))
                .catch(this.onChannelError.bind(this));
        } else {
            return Promise.resolve(false);
        }
    }

    public leaveChannel(): Promise<any> {
        if (!this.joinedChannel) {
            return Promise.resolve(true);
        }
        if (!this.invoking && ssmTokenService.hasToken()) {
            this.invoking = true;
            return this.connection.invoke('LeaveMyChannel')
                .then(this.onLeaveChannel.bind(this))
                .catch(this.onChannelError.bind(this));
        } else {
            return Promise.resolve(false);
        }
    }

    private addListenFor(messageListenFor: string, eventMethod: (...args: any[]) => void) {
        this.connection.on(messageListenFor, eventMethod);
    }

    private listenForMessages() {
        this.messages.forEach((message: MessageListenFor) => {
            this.addListenFor(message.EventName, message.EventMethod);
            this.removeMessage(message);
        });
    }

    private saveMessage(message: MessageListenFor) {
        this.messages.push(message);
        setTimeout(() => {
            this.connect();
        }, 500);
    }

    private removeMessage(message: MessageListenFor) {
        const index = this.messages.indexOf(message);
        if (index !== -1) {
            this.messages.splice(index, 1);
        }
    }

    private onJoinChannel(res: any) {
        this.joinedChannel = true;
        this.invoking = false;
        this.listenForMessages();
        return res;
    }

    private onLeaveChannel(res: any) {
        this.joinedChannel = false;
        this.invoking = false;
        return res;
    }

    private onChannelError(error: any) {
        this.invoking = false;
        this.joinedChannel = false;
        this.processError(error);
    }

    private onConnectionStart(res: any) {
        this.connected = true;
        this.connecting = false;
        VueInstanceService.vueinstance.$emit("change-web-socket", this.connected);
        this.joinChannel();
        return res;
    }

    private onConnectionError(error: any) {
        this.connecting = false;
        this.connected = false;
        VueInstanceService.vueinstance.$emit("change-web-socket", this.connected);
        this.processError(error);
    }

    private processError(error: any, showMessageError: boolean = true) {
        const message = error.message || error.response.statusText;

        if (!UtilsString.IsNullOrWhiteSpace(message) && showMessageError) {
            ssmMessageService.toast(message, ssmMessageService.TypeError);
        }

        throw error;
    }
}
export const ssmSignalRService = new SignalRService();
